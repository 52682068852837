import { API_TEMPLATE } from '../libs/constant'
import { get, patch, post, put, upload as _upload, callDelete } from '../libs/query'
import { formatUrl } from '../libs/url'

const userApi = {
	addPermission({ roleId, permissionIds }) {
		const path = API_TEMPLATE.USER_PERMISSION_ADD
		return post(path, { roleId, permissionIds })
	},

	changePassword({ password, userId }) {
		const path = API_TEMPLATE.USER_CHANGE_PASSWORD
		return post(path, { password, userId })
	},

	checkStudent(contactChildrenId) {
		return post(`${API_TEMPLATE.USER_CHECK_STUDENT}?contactChildrenId=${contactChildrenId}`)
	},

	create({ user }) {
		const path = API_TEMPLATE.USER_CREATE
		return post(path, user)
	},

	createTeacher({ user }) {
		const path = API_TEMPLATE.USER_CREATE_TEACHER
		return post(path, user)
	},

    createCustomerCare ({ user }) {
		const path = API_TEMPLATE.USER_CREATE_CUSTOMER_CARE
		return post(path, user)
    },

	detail(id) {
		const path = formatUrl(API_TEMPLATE.USER_DETAIL, [{ key: 'id', value: id }])
		return get(path)
	},

	detailRole(id) {
		const path = formatUrl(API_TEMPLATE.USER_ROLE_DETAIL, [{ key: 'roleId', value: id }])
		return get(path)
	},

	detailStudent(id) {
		const path = formatUrl(API_TEMPLATE.USER_DETAIL_STUDENT, [{ key: 'id', value: id }])
		return get(path)
	},
	checkNewStudent(id) {
		const path = formatUrl(API_TEMPLATE.CHECK_NEW_STUDENT, [{ key: 'id', value: id }])
		return get(path)
	},
	detailTeacher(id) {
		const path = formatUrl(API_TEMPLATE.USER_DETAIL_TEACHER, [{ key: 'id', value: id }])
		return get(path)
	},

	edit({ user }) {
		const path = API_TEMPLATE.USER_EDIT
		return patch(path, user)
	},

	editStudent({ user }) {
		const path = API_TEMPLATE.USER_EDIT_STUDENT
		return patch(path, user)
	},

	editStudentInfo({ user }) {
		const path = API_TEMPLATE.USER_EDIT_STUDENT_INFO
		return patch(path, user)
	},

	editTeacher({ user }) {
		const path = API_TEMPLATE.USER_EDIT_TEACHER
		return patch(path, user)
	},
	editTeacherProfile(payload) {
		const path = API_TEMPLATE.USER_EDIT_TEACHER_PROFILE
		return patch(path, payload)
	},

	uploadImage({file, userId}) {
		const form = new FormData()
		form.append('image', file)
		form.append('userId', userId)
		const path = API_TEMPLATE.USER_UPLOAD_FILE
	
		return _upload(path, form)
	},
	removeUploadImage({ userId }) {
		const path = `${API_TEMPLATE.USER_DELETE_IMAGE_FILE}?userId=${userId}`
		return callDelete(path)
	},

	editUserCustomerCare ({ user }) {
		const path = API_TEMPLATE.USER_EDIT_CUSTOMER_CARE
		return patch(path, user)
	},
  
	exportStudent({ payload, sheetId, sheetName }) {
		return post(`${API_TEMPLATE.USER_EXPORT_STUDENT}?sheetId=${sheetId}&sheetName=${sheetName}`, payload)
	},

	findStudentsByPhone(phone) {
		const path = API_TEMPLATE.USER_FIND_STUDENTS_BY_PHONE
		return get(`${path}?phone=${phone}`)
	},

	importTeacher({ sheetId, sheetName }) {
		return post(`${API_TEMPLATE.USER_IMPORT_TEACHER}?sheetId=${sheetId}&sheetName=${sheetName}`)
	},

	exportTeacher({ sheetId, sheetName, criteria, page, size }) {
		return post(`${API_TEMPLATE.USER_EXPORT_TEACHER}?sheetId=${sheetId}&sheetName=${sheetName}`, {
			criteria: criteria,
			page,
			size
		})
	},

	lastClass(id) {
		const path = API_TEMPLATE.USER_LAST_CLASS_STUDENT
		return get(`${path}?studentId=${id}`)
	},

	list({ criteria, page, size }) {
		const path = API_TEMPLATE.USER_FILTER
		return post(path, { criteria, page, size })
	},

	listPermission() {
		const path = API_TEMPLATE.USER_PERMISSION_LIST
		return get(path)
	},

	listProvince() {
		const path = API_TEMPLATE.PROVINCE_LIST
		return get(path)
	},

	listRole() {
		const path = API_TEMPLATE.USER_ROLE_LIST
		return get(path)
	},

	listStudent({ criteria, page, size }) {
		const path = API_TEMPLATE.USER_FILTER_STUDENT
		return post(path, { criteria, page, size })
	},

	getAllStudent() {
		const path = API_TEMPLATE.USER_OVERVIEW
		return get(path)
	},

	listTeacher({ criteria, page, size }) {
		return post(`${API_TEMPLATE.USER_FILTER_TEACHER}?isActive=true`, { criteria, page, size })
	},

	listTeacherWithLimit({ criteria, page, size }) {
		return post(`${API_TEMPLATE.USER_FILTER_TEACHER_LIMIT}`, { criteria, page, size })
	},

	orderDataStudent(crmChildId) {
		const path = API_TEMPLATE.USER_ORDER_DATA
		return get(`${path}?crmChildId=${crmChildId}`)
	},

	orderDataStudentOldVerion(studentId) {
		const path = API_TEMPLATE.USER_ORDER_DATA_OLD_VERSION
		return get(`${path}?studentId=${studentId}`)
	},

	orderDataStudentById(id) {
		const path = API_TEMPLATE.USER_ORDER_DATA_BY_ID
		return get(`${path}?id=${id}`)
	},

	getCRMchildID(id) {
		const path = API_TEMPLATE.USER_CRM_CHILD_ID
		return get(`${path}?crm2Id=${id}`)
	},

	removePermission({ roleId, permissionIds }) {
		const path = API_TEMPLATE.USER_PERMISSION_REMOVE
		return post(path, { roleId, permissionIds })
	},

	searchByRole(role) {
		const path = API_TEMPLATE.USER_LIST
		return get(`${path}?role=${role}&suspended=${0}`)
	},

	searchByRoleLocked(role) {
		const path = API_TEMPLATE.USER_LIST
		return get(`${path}?role=${role}&suspended=${1}`)
	},

	searchTeacher({ keyword }) {
		const path = formatUrl(API_TEMPLATE.USER_SEARCH_TEACHER, [{ key: 'keyword', value: keyword }])
		return get(path)
	},

	searchUser({ keyword, role }) {
		const path = formatUrl(API_TEMPLATE.USER_SEARCH_USER, [
			{ key: 'keyword', value: keyword },
			{ key: 'role', value: role }
		])
		return get(path)
	},

	suspend({ id, suspended }) {
		const path = API_TEMPLATE.USER_SUSPEND
		return post(path, { id, suspended })
	},

	updateStudentProfile(payload) {
		const path = API_TEMPLATE.USER_UPDATE_STUDENT_PROFILE
		return post(path, payload)
	},
	searchAnyByUserName(text) {
		return post(`${API_TEMPLATE.USER_SEARCH_BY_USERNAME}?userName=${text}&page=0&size=200`)
	},
	listTeacherByGradeAndType({ grade, type }) {
		return get(`${API_TEMPLATE.USER_TEACHER_BY_GRADE_AND_TYPE}?grade=${grade}&type=${type}`)
	},
	/**
	 * DEPRECATED: Bỏ, không dùng
	 */
	updateClassInBrowserCookie(payload) {
		return post(API_TEMPLATE.USER_UPDATE_CLASSIN_BROWSER_COOKIE, payload)
	},
	getAllCohort() {
		return get(API_TEMPLATE.COHORT_GET_ALL)
	},
	addCohortStudent(payload) {
		return post(API_TEMPLATE.COHORT_ADD_STUDENT, payload)
	},
	removeCohortStudent(payload) {
		return post(API_TEMPLATE.COHORT_REMOVE_STUDENT, payload)
	},
	addAllCohortStudent(payload) {
		return post(API_TEMPLATE.COHORT_ADD_ALL_STUDENT, payload)
	},

	//student about to expire
	getParentFeedbacks(id) {
		return get(`${API_TEMPLATE.USER_GET_DETAIL}?studentId=${id}`)
	},
	filterStudentExpire(payload, queryString) {
		return post(API_TEMPLATE.USER_EXPIRE_LIST_FILTER, { ...payload, ...queryString })
	},
	importCustomerCareResults({ sheetId, sheetName }) {
		return post(`${API_TEMPLATE.USER_IMPORT_CUSTOMER_CARE_RESULTS}?sheetId=${sheetId}&sheetName=${sheetName}`)
	},
	exportCustomerCareResults({ payload, sheetId, sheetName }) {
		return post(`${API_TEMPLATE.USER_EXPORT_CUSTOMER_CARE_RESULTS}?sheetId=${sheetId}&sheetName=${sheetName}`, payload)
	},
	downloadTemplate() {
		return get(API_TEMPLATE.USER_DOWNLOAD_TEMPLATE)
	},
	updateFeedbackParent(payload) {
		return post(API_TEMPLATE.UPDATE_FEEDBACK_PARENT, payload)
	},
	getAddInfoPotentialPoint(id) {
		return get(`${API_TEMPLATE.GET_ADD_INFO_POTENTIAL_POINT}?parentsFeedbackId=${id}`)
	},
	updateCallStatus({id, callStatus}) {
		return post(`${API_TEMPLATE.UPDATE_CALL_STATUS_EXPIRE}?id=${id}&callStatus=${callStatus}`)
	},

	//api bảo lưu
	getReservesList(studentId) {
		return get(`${API_TEMPLATE.USER_RESERVES_LIST}?studentId=${studentId}`)
	},
	createReserve(payload) {
		return post(`${API_TEMPLATE.USER_RESERVES_CREATE}`, payload)
	},
	editReserve(payload) {
		return post(`${API_TEMPLATE.USER_RESERVES_EDIT}`, payload)
	},
	cancelReserve(payload) {
		return post(`${API_TEMPLATE.USER_RESERVES_CANCEL}`, payload)
	},
	detailPackagesReserve(reserveId) {
		return get(`${API_TEMPLATE.DETAIL_PACKAGES_RESERVE}?id=${reserveId}`)
	},

	//api chuyển nhượng
	transfersList(payload) {
		return post(API_TEMPLATE.TRANSFER_FILTER, {
			...payload,
			sortOrders: [
				{
					field: 'createdAt',
					isASC: false
				}
			]
		})
	},
	transferCreate(payload) {
		return post(`${API_TEMPLATE.TRANSFER_CREATE}`, payload)
	},
	transferDetails(transferId) {
		return get(`${API_TEMPLATE.TRANSFER_DETAIL}?id=${transferId}`)
	},
	noteTransferForAssignClass({ toStudentId, taskArrangeClassId }) {
		return get(`${API_TEMPLATE.TRANSFER_DETAIL_NOTE}?toStudentId=${toStudentId}&taskArrangeClassId=${taskArrangeClassId}`)
	},
	// Api danh sách lịch sử tin nhắn theo id học sinh
	getMessageHistoryListByStudentId(studentId) {
		return get(`${API_TEMPLATE.GET_MESSAGE_HISTORY_LIST_BY_STUDENT_ID}?studentId=${studentId}&pageIndex=0&pageSize=999`)
	},

	// Api chuyển chương trình
	getOrderHaveCreditByStudentId(studentId) {
		return get(`${API_TEMPLATE.GET_ORDER_HAVE_CREDIT_BY_STUDENT_ID}?studentId=${studentId}`)
	},
	createProgramConversion(payload) {
		return post(`${API_TEMPLATE.CREATE_PROGRAM_CONVERSION}`, payload)
	},
	getListConversionByStudentId(studentId) {
		return get(`${API_TEMPLATE.GET_LIST_PROGRAM_CONVERSION}?studentId=${studentId}&page=0&size=9999`)
	},
	getDetailConversionById(conversionId) {
		return get(`${API_TEMPLATE.GET_DETAIL_PROGRAM_CONVERSION}/${conversionId}`)
	},
	cancelProgramConversion(payload) {
		return post(`${API_TEMPLATE.CANCEL_PROGRAM_CONVERSION}`, payload)
	},

	// api lấy kết quả học tập
	getLearningAttitude({ studentId, startTime, endTime }) {
		return get(`${API_TEMPLATE.GET_LEARNING_ATTITUDE}?studentId=${studentId}&fromLearnDate=${startTime}&toLearnDate=${endTime}`)
	},
	getLearningAttitudeTable({ studentId, startTime, endTime, isQualified }) {
		return get(`${API_TEMPLATE.GET_LEARNING_ATTITUDE_TABLE}?studentId=${studentId}&fromLearnDate=${startTime}&toLearnDate=${endTime}&isQualified=${isQualified}`)
	},
	getLearningAttitudeSummary({ studentId, startTime, endTime }) {
		return get(`${API_TEMPLATE.GET_LEARNING_ATTITUDE_SUMMARY_FOLLOW_CHART}?studentId=${studentId}&fromLearnDate=${startTime}&toLearnDate=${endTime}`)
	},
	getLearningAttitudeByMonth({ studentId, startTime, endTime }) {
		return get(`${API_TEMPLATE.GET_LEARNING_ATTITUDE_BY_MONTH}?studentId=${studentId}&fromLearnDate=${startTime}&toLearnDate=${endTime}`)
	},
	
	getLearningOutcomes({ studentId, startTime, endTime }) {
		return get(`${API_TEMPLATE.GET_LEARNING_OUTCOMES}?studentId=${studentId}&fromLearnDate=${startTime}&toLearnDate=${endTime}`)
	},
	getLearningOutcomesTable({ studentId, startTime, endTime }) {
		return get(`${API_TEMPLATE.GET_LEARNING_OUTCOMES_TABLE}?studentId=${studentId}&fromLearnDate=${startTime}&toLearnDate=${endTime}`)
	},
	getLearningOutcomesSummary({ studentId, startTime, endTime, status,	minGrade, maxGrade }) {
		return get(`${API_TEMPLATE.GET_LEARNING_OUTCOMES_SUMMARY_FOLLOW_CHART}?studentId=${studentId}&fromLearnDate=${startTime}&toLearnDate=${endTime}&status=${status}&minGrade=${minGrade}&maxGrade=${maxGrade}`)
	},

	getDiligence({ studentId, startTime, endTime }) {
		return get(`${API_TEMPLATE.GET_DILIGENCE}?studentId=${studentId}&fromLearnDate=${startTime}&toLearnDate=${endTime}`)
	},
	getDiligenceSummary({ studentId, startTime, endTime }) {
		return get(`${API_TEMPLATE.GET_DILIGENCE_SUMMARY}?studentId=${studentId}&fromLearnDate=${startTime}&toLearnDate=${endTime}`)
	},
	getDiligenceFollowStudy({ studentId, startTime, endTime }) {
		return get(`${API_TEMPLATE.GET_DILIGENCE_FOLLOW_STUDY}?studentId=${studentId}&fromLearnDate=${startTime}&toLearnDate=${endTime}`)
	},
	getDiligenceFollowExam({ studentId, startTime, endTime, status,	minGrade, maxGrade }) {
		return get(`${API_TEMPLATE.GET_DILIGENCE_FOLLOW_EXAM}?studentId=${studentId}&fromLearnDate=${startTime}&toLearnDate=${endTime}&status=${status}&minGrade=${minGrade}&maxGrade=${maxGrade}`)
	},
	getDiligenceOverviewChart({ studentId, startTime, endTime }) {
		return get(`${API_TEMPLATE.GET_DILIGENCE_OVERVIEW_CHART}?studentId=${studentId}&fromLearnDate=${startTime}&toLearnDate=${endTime}`)
	},

	getImprovementStudent(id) {
		return get(`${API_TEMPLATE.GET_IMPROVEMENT_STUDENT}/${id}/paper-conclusion`)
	},
	getStatusStudentInSession({ studentId, sessionId }) {
		return get(`${API_TEMPLATE.GET_STATUS_STUDENT_IN_SESSION}/${sessionId}/student/${studentId}/tag`)
	},

	//api lấy thông tin học sinh chỉ dùng trong màn Thông tin học sinh
	getStudentInfo(studentId) {
		return get(`${API_TEMPLATE.GET_STUDENT_INFO_BY_ID}?id=${studentId}`)
	},

	//api lấy mức độ tiềm năng
	getListPotentialLevel({ studentId }) {
		return get(`${API_TEMPLATE.GET_LIST_POTENTIAL_POINTS}?studentId=${studentId}`)
	},
	getHistoryPotentialPoint({ studentId, from, to }) {
		return get(`${API_TEMPLATE.GET_HISTORY_POTENTIAL_POINTS}?studentId=${studentId}&from=${from}&to=${to}`)
	},
	getHistoryPotentialLevelIntimacy({ studentId, from, to }) {
		return get(`${API_TEMPLATE.GET_HISTORY_POTENTIAL_LEVEl_INTIMACY}?studentId=${studentId}&from=${from}&to=${to}`)
	},
	getHistoryPotentialLevelSatisfaction({ studentId, from, to }) {
		return get(`${API_TEMPLATE.GET_HISTORY_POTENTIAL_LEVEl_SATISFACTION}?studentId=${studentId}&from=${from}&to=${to}`)
	},
	getDetailPotential(id) {
		return get(`${API_TEMPLATE.GET_DETAIL_POTENTIAL_POINTS}?id=${id}`)
	}
}

export default userApi
